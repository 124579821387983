/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-await-in-loop */
import { useLocation, useNavigate } from "react-router-dom";
import PublishLayout from "examples/LayoutContainers/PublishLayout";
import PublishNavbar from "examples/Navbars/PublishNavbar";
import React, { useState, useEffect, createRef } from "react";
import { useTranslation } from "react-i18next";
import ApiService from "api/ApiService";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import PDFViewer from "layouts/pages/add-file/PDFViewer";
import MDBox from "components/MDBox";
import { isObjectEmpty, formatDate } from "api/utils";
import Footer from "examples/Footer";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import ActionCell from "layouts/ecommerce/orders/order-list/components/ActionCell";
import moment from "moment";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Header from "./components/Header";
import OrderInfo from "./components/OrderInfo";
import OrderSummary from "./components/OrderSummary";

function PublishedDocument() {
  const location = useLocation();

  // Retrieve package search parameters
  const query = new URLSearchParams(location.search);
  const packageParams = {
    packageId: query.get("packageId"),
    publicShareSecret: query.get("publicShareSecret"),
  };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const fileInput = createRef();

  const [documentDetails, setDocumentDetails] = useState({});
  const [showDocument, setShowDocument] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showReminderDetails, setShowReminderDetails] = useState(false);
  const [variant, setVariant] = useState("info");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [pdfReport, setPdfReport] = useState("");
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [showOwnerActions, setShowOwnerActions] = useState(true);
  const [SDCAddress, setSDCAddress] = useState("");
  const [timeDuration, setTimeDuration] = useState(1);
  const [timeValue, setTimeValue] = useState("year(s)");
  const [fileTime, setFileTime] = useState(moment().add(7, "days"));
  const [title, setTitle] = useState("");
  const [profile, setProfile] = useState({});
  const [packageMetadata, setPackageMetadata] = useState("");
  const [packageMetadataName, setPackageMetadataName] = useState("");
  const [packageMetadataType, setPackageMetadataType] = useState(ApiService.metadataTypes.string);
  const [lookupUsers, setLookupUsers] = useState({});
  const [uploadNotification, setShowUploadNotification] = useState(false);
  const [openExtendEolModal, setOpenExtendEolModal] = useState(false);
  const [disableSign, setDisableSign] = useState(true);
  const [pdfPages, setPdfPages] = useState(1);
  const [metadataModal, setOpenMetadataModal] = useState(false);
  const [openReminderModal, setOpenReminderModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [accessRead, setAccessRead] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [verifyHashResponse, setVerifyHashResponse] = useState("");
  const [verifiedHash, setVerifiedHash] = useState("");
  const [loading, setLoading] = useState(false);

  const [reminderEmail, setReminderEmail] = useState("");
  const [reminderSubject, setReminderSubject] = useState("");
  const [reminderMessage, setReminderMessage] = useState("");
  const [reminderDate, setReminderDate] = useState(null);
  const [reminderReceiversList, setReminderReceiversList] = useState([]);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [updateSubject, setUpdateSubject] = useState("");
  const [updateMessage, setUpdateMessage] = useState("");
  const [updatescheduleActivationDate, setUpdatescheduleActivationDate] = useState(null);
  const [updateReceivers, setUpdateReceivers] = useState([]);
  const [updateEmail, setUpdateEmail] = useState("");

  const [openEditReminder, setOpenEditReminder] = useState(false);
  const [editReminder, setEditReminder] = useState({});
  const [deleteReminderResp, setDeleteReminderResp] = useState({});
  const [editReminderResp, setEditReminderResp] = useState({});
  const [addReminderResp, setAddReminderResp] = useState({});

  useEffect(() => {
    if (timeValue === t("days")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "days"));
    }
    if (timeValue === t("weeks")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "weeks"));
    }
    if (timeValue === t("months")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "months"));
    }
    if (timeValue === t("years")) {
      setFileTime(moment(documentDetails.endOfLife).add(timeDuration, "years"));
    }
  }, [timeDuration, timeValue]);

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = () => {
    setShowDocument(true);
  };

  const handleClickDetails = () => {
    setShowDetails(true);
  };

  const handleHideClickDetails = () => {
    setShowDetails(false);
  };
  const handleClickReminderDetails = () => {
    setShowReminderDetails(true);
  };
  const handleHideClickReminderDetails = () => {
    setShowReminderDetails(false);
  };
  const handleHideDocument = () => {
    setShowDocument(false);
  };

  useEffect(() => {
    const getUserSignature = async () => {
      try {
        await ApiService.readUserDocument({
          userId: profile._id,
          documentCriteria: ApiService.documentCriteria.documentType,
          documentFilter: ApiService.documentTypes.signature,
        });
      } catch (e) {
        setShowUploadNotification(true);
        console.error(e.message);
      }
    };
    !isObjectEmpty(profile) && getUserSignature();
  }, [profile]);

  // useEffect(() => {
  //   const getUsers = async () => {
  //     const responseData = await ApiService.getAllUsers({});
  //     const users = {};
  //     // eslint-disable-next-line no-restricted-syntax
  //     for (const entry of responseData) {
  //       users[entry.address] = entry.user.email;
  //     }
  //     setLookupUsers(users);
  //   };
  //   getUsers();
  // }, []);

  const queryParameters = {};

  const getFrontendUrl = () => {
    const url = new URL(`${ApiService.serviceFrontendURL}/authentication/sign-in`);
    url.search = new URLSearchParams({
      redirectPath: `/documents/details?${queryParameters}`,
    });

    return url.toString();
  };

  const handleDownloadDocument = (download = false) => {
    const handlePreviewFile = async (userId, parameters, signal) => {
      const params = {
        jobId: parameters.jobId,
        sender: parameters.details.senderAddress,
        receiver: parameters.details.receiverAddress,
        data_id: parameters.details.data_id,
        hash: parameters.details.rawHash,
      };
      const responseData = await ApiService.downloadPublicFile(params, signal);

      // const a = document.createElement("a");
      // a.href = window.URL.createObjectURL(responseData);
      // a.download = documentDetails.fileName;
      // a.click();

      if (download) {
        const titlePrefix = ""; // checkConfirmed();
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(responseData);
        a.download = `${documentDetails.title.replace(/"/g, "").replace(/\.pdf/gi, "")}${
          titlePrefix.length > 0 ? ` - ${titlePrefix}.pdf` : ".pdf"
        }`;

        a.click();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(responseData);
        reader.onloadend = function () {
          const base64data = reader.result;
          setPdfReport(base64data);
        };
      }
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      let responseData;
      try {
        responseData = await ApiService.preparePublicDownloadPackageFile(
          {
            packageId: packageParams.packageId,
            publicShareSecret: packageParams.publicShareSecret,
          },
          signal
        );
      } catch (e) {
        console.error(e.message);
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestPublicDownloadJobStatus(jobParams, signal);
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error(e.message);
      }
    };
    handlePrepareFileDownload();
  };

  const handleDownloadReport = (download = true) => {
    const handlePreviewFile = async (userId, parameters, signal) => {
      const reportParams = {
        jobId: parameters.jobId,
      };
      let responseData;
      try {
        responseData = await ApiService.downloadReport(reportParams, signal);
      } catch (e) {
        console.error(e.message);
        return;
      }

      // const checkConfirmed = () => {
      //   if (documentDetails.accessRights.length === 0 && documentDetails.signatures.length > 0) {
      //     return t("confirmed");
      //   }
      //   const signSet = new Set();
      //   documentDetails.signatures.map((sig) => signSet.add(lookupUsers[sig.sender]));

      //   const accessSet = new Set();
      //   documentDetails.accessRights
      //     .filter((access) => access.type === "SIGN")
      //     .map((acc) => accessSet.add(acc.email));

      //   if (accessSet.size === 0 && signSet.size > 0) {
      //     return t("confirmed");
      //   }
      //   if (
      //     (signSet.size === accessSet.size &&
      //       !accessSet.has(profile.email) &&
      //       !signSet.has(profile.email) &&
      //       documentDetails.accessRights.filter((acc) => acc.email === profile.email).length ===
      //         0) ||
      //     signSet.size === accessSet.size + 1
      //   ) {
      //     return t("confirmed");
      //   }
      //   return "";
      // };

      if (download) {
        const titlePrefix = ""; // checkConfirmed();
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(responseData);
        a.download = `${documentDetails.title.replace(/"/g, "").replace(/\.pdf/gi, "")}${
          titlePrefix.length > 0 ? ` - ${titlePrefix}.pdf` : ".pdf"
        }`;

        a.click();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(responseData);
        reader.onloadend = function () {
          const base64data = reader.result;
          setPdfReport(base64data);
        };
      }
    };

    const handlePrepareFileDownload = async () => {
      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;

      const reportParams = {
        parentId: documentDetails._id,
        parentType: "PACKAGE",
        frontendUrl: getFrontendUrl(),
        creatorName: "SignOnChain",
        documentTitle: "SignOnChain Report",
        reportTitle: "report",
      };
      let responseData;
      try {
        responseData = await ApiService.prepareDownloadReport(reportParams, signal);
      } catch (e) {
        console.error(e.message);
        return;
      }
      const jobId = responseData.jobId;
      try {
        const jobParams = { jobId };
        while (!signal.aborted && responseData.progress !== 100) {
          responseData = await ApiService.queryLatestJobStatus(jobParams, signal);
          if (responseData.status === ApiService.jobStatus.failed) {
            console.error("Error downloading package 1");
          }
          if (
            responseData.status === ApiService.jobStatus.done ||
            responseData.status === ApiService.jobStatus.failedBlockchain
          ) {
            await handlePreviewFile(undefined, responseData, signal);
          }
        }
        if (signal.aborted) {
          console.error("Operation aborted");
        }
      } catch (e) {
        console.error("Error downloading package 2");
      }
    };
    handlePrepareFileDownload();
  };

  useEffect(() => {
    !isObjectEmpty(documentDetails) && handleDownloadDocument(false);
  }, [documentDetails]);

  const handleDeleteAccess = async (id) => {
    const params = {
      parentId: documentDetails._id,
      parentType: "PACKAGE",
      accessRightsId: id,
    };

    // Initialize abort controller
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      await ApiService.deleteAccessRights(params, signal);
      setShow(true);
      setTitle(t("file-access"));
      setVariant("info");
      setMessage(t("removed-access"));
    } catch (e) {
      setShow(true);
      setTitle(t("file-access"));
      setVariant("warning");
      setMessage(e.message);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const apiOperations = async () => {
      let documentData;

      try {
        documentData = await ApiService.getPublicPackageDetails(packageParams, signal);
      } catch (e) {
        console.error(e);
        setShow(true);
        setTitle(t("document-details"));
        setVariant("warning");
        setMessage(e.message);
        setShowError(true);
      } finally {
        setLoadingDetails(false);
      }

      setDocumentDetails(documentData.package);
    };
    apiOperations();
    return () => {
      abortController.abort();
    };
  }, []);

  const checkSigningOrder = async () => {
    const myAccess = documentDetails.accessRights.filter(
      (access) => access.email === profile.email && access.signingOrder
    );
    if (myAccess.length > 0) {
      const order = myAccess[0].signingOrder;
      if (order) {
        const nextAccess = documentDetails.accessRights.filter(
          (access) => access.signingOrder === order + 1
        );
        const abortController = new AbortController();
        const signal = abortController.signal;
        const params = {
          parentId: documentDetails._id,
          parentType: "PACKAGE",
          accessRightsId: nextAccess._id,
          frontendUrl: getFrontendUrl(),
          populateMagicLink: "true",
        };

        try {
          await ApiService.inviteAccessRights(params, signal);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const handleOpenExtend = () => {
    setOpenExtendEolModal(true);
  };

  const [openVerifyHash, setOpenVerifyHash] = useState(false);

  const handleVerifyHash = () => {
    setOpenVerifyHash(true);
  };

  const handleCloseVerify = () => {
    setOpenVerifyHash(false);
    setUploadedFile(null);
    setLoading(false);
    setVerifyHashResponse("");
  };

  const handleVerifyDocHash = async () => {
    setLoading(true);
    let responseData;
    try {
      responseData = await ApiService.calcHashFile({
        file: uploadedFile,
      });
    } catch (e) {
      console.error(e);
    }

    const jobId = responseData.jobId;

    try {
      const jobParams = { jobId };
      const abortController = new AbortController();
      const signal = abortController.signal;
      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);

        if (responseData.status === ApiService.jobStatus.failed) {
          setShow(true);
          setTitle(t("verify-hash"));
          setVariant("error");
          setMessage(t("error-checking-hash"));
          setLoading(false);
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setVerifyHashResponse(
            responseData.details.hash === documentDetails.hash ? t("validated") : t("no-match")
          );
          setVerifiedHash(responseData.details.hash);
          setLoading(false);
        }
      }
      if (signal.aborted) {
        setShow(true);
        setVariant("error");
        setTitle(t("extend-eol"));
        setMessage(t("operation-aborted"));
        setLoading(false);

        return;
      }
    } catch (e) {
      setShow(true);
      setVariant("error");
      setTitle(t("extend-eol"));
      setMessage(t("error-uploading-file"));
      setLoading(false);
    }
  };

  const handleCloseExtend = () => {
    setOpenExtendEolModal(false);
  };

  const handleExtendEol = async () => {
    const params = {
      data_id: query.get("data_id"),
      hash: query.get("hash"),
      receiver: query.get("receiver"),
      ttl: Math.ceil((fileTime.endOf("day") - moment()) / 1000.0),
    };

    const abortController = new AbortController();
    const signal = abortController.signal;
    let responseData;
    try {
      responseData = await ApiService.updatePackageTTL(params);
    } catch (e) {
      console.error(e.message);
      setShow(true);
      setTitle(t("extend-eol"));
      setVariant("warning");
      setMessage(e.message);
    }

    const jobId = responseData.jobId;

    try {
      const jobParams = { jobId };

      while (!signal.aborted && responseData.progress !== 100) {
        responseData = await ApiService.queryLatestJobStatus(jobParams, signal);

        if (responseData.status === ApiService.jobStatus.failed) {
          setShow(true);
          setTitle(t("extend-eol"));
          setVariant("error");
          setMessage(t("error-extending-eol"));
          return;
        }
        if (
          responseData.status === ApiService.jobStatus.done ||
          responseData.status === ApiService.jobStatus.failedBlockchain
        ) {
          setShow(true);
          setVariant("primary");
          setTitle(t("extend-eol"));
          setMessage(t("successfully-extended-eol"));
        }
      }
      if (signal.aborted) {
        setShow(true);
        setVariant("error");
        setTitle(t("extend-eol"));
        setMessage(t("operation-aborted"));
        return;
      }
    } catch (e) {
      setShow(true);
      setVariant("error");
      setTitle(t("extend-eol"));
      setMessage(t("error-uploading-file"));
    }
    handleCloseExtend();
  };

  useEffect(() => {
    const checkCanSign = () => {
      const onlyRead = documentDetails.accessRights.filter(
        (access) => access.email === profile.email && access.type === "READ"
      );
      if (onlyRead.length > 0) {
        return setAccessRead(true);
      }
      const disable =
        documentDetails.signatures.filter((sign) => lookupUsers[sign.sender] === profile.email)
          .length > 0;
      return setDisableSign(disable);
    };
    !isObjectEmpty(profile) && !isObjectEmpty(documentDetails) && checkCanSign();
  }, [profile, documentDetails]);

  const deletePackageMetadataAsync = (data) => {
    const handlePackageMetadataDeletion = async () => {
      // Deletion parameters
      const params = {
        parentId: documentDetails._id,
        parentType: ApiService.parentTypes.package,
        metadataId: data._id,
      };

      // Initialize abort controller
      const abortController = new AbortController();
      const signal = abortController.signal;
      try {
        await ApiService.deleteMetadata(params, signal);
      } catch (e) {
        console.error(e);
      }
    };

    handlePackageMetadataDeletion();
  };

  const deleteReminder = async (rem) => {
    try {
      const resp = await ApiService.deleteReminder({
        parentId: rem.parentId,
        parentType: rem.parentType,
        reminderId: rem._id,
      });
      setDeleteReminderResp(resp);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenEditReminder = (rem) => {
    setUpdateSubject(rem.subject);
    setUpdateMessage(rem.message[0]);
    setUpdatescheduleActivationDate(moment(rem.scheduleActivationDate));
    setUpdateReceivers(rem.receivers.map((rec) => rec.receiver));
    setEditReminder(rem);
    setOpenEditReminder(true);
  };

  const handleSetLoading = (bool) => {
    setLoading(bool);
  };

  const prepareDataForTable = (metadata) =>
    metadata.map((elem) => ({
      user: `${lookupUsers[elem.user]} (${elem.user})`,
      date: formatDate(elem.date),
      type: elem.type,
      name: elem.name,
      metadata: elem.metadata,
      actions: (
        <MDBox mr={1}>
          <Tooltip title={t("delete")} placement="top">
            <MDButton
              color="warning"
              size="small"
              iconOnly
              circular
              onClick={() => {
                deletePackageMetadataAsync(elem);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
            </MDButton>
          </Tooltip>
        </MDBox>
      ),
    }));

  const prepareRemindersForTable = (reminders) =>
    reminders.map((elem) => ({
      sender: `${lookupUsers[elem.sender]}`,
      createdAt: formatDate(elem.createdAt),
      subject: elem.subject,
      message: elem.message[0],
      remindAt: elem.scheduleActivationDate,
    }));

  const structureDataForTable = (files) => {
    const dataTableData = {
      columns: [
        {
          Header: t("user"),
          accessor: "user",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("date"),
          accessor: "date",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("type"),
          accessor: "type",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("name"),
          accessor: "name",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("metadata"),
          accessor: "metadata",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("actions"),
          accessor: "actions",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareDataForTable(files),
    };
    return dataTableData;
  };

  const structureRemindersForTable = (files) => {
    const dataTableData = {
      columns: [
        {
          Header: t("sender"),
          accessor: "sender",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("created-at"),
          accessor: "createdAt",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("subject"),
          accessor: "subject",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("message"),
          accessor: "message",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("remind-at"),
          accessor: "remindAt",
          Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
          Header: t("actions"),
          accessor: "actions",
          Cell: ({ value }) => <ActionCell actions={value} />,
        },
      ],
      rows: prepareRemindersForTable(files),
    };
    return dataTableData;
  };

  return (
    <PublishLayout>
      <PublishNavbar />
      {loading && <LinearProgress sx={{ overflow: "hidden" }} />}
      {showError ? (
        <MDBox my={6}>
          <MDBox sx={{ display: { xs: "none", md: "block" } }} style={{ textAlign: "center" }}>
            <MDTypography variant="h4" fontWeight="bold">
              {t("invalid-document-link")}
            </MDTypography>
            <MDBox pt={5}>
              <MDBox mb={3}>
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  verticalAlign="middle"
                >
                  {t(
                    "the-document-you-are-trying-to-access-does-not-exist-There-can-be-several-reasons-to-his"
                  )}
                </MDTypography>
              </MDBox>

              <MDBox>
                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("the-documents-end-of-life-has-been-reached-and-document-has-been-purged")}
                  </MDTypography>
                </MDBox>

                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("you-no-longer-have-access-to-this-document")}
                  </MDTypography>
                </MDBox>

                <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    verticalAlign="middle"
                  >
                    {t("the-link-you-clicked-is-incorrect")}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <>
          <MDBox my={6}>
            {showDocument ? (
              <>
                <MDBox style={{ display: "inline-flex" }}>
                  <MDBox mb={2}>
                    <MDButton variant="outlined" color="primary" onClick={handleHideDocument}>
                      {t("back-to-details")}
                    </MDButton>
                  </MDBox>
                </MDBox>

                {pdfReport.length > 0 && (
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                      <PDFViewer file={pdfReport} />
                    </Grid>
                  </Grid>
                )}
              </>
            ) : showDetails ? (
              <MDBox style={{ display: "inline-flex" }}>
                <MDBox mb={2}>
                  <MDButton variant="outlined" color="primary" onClick={handleHideClickDetails}>
                    {t("back-to-details")}
                  </MDButton>
                  <MDBox my={3}>
                    <Card>
                      <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {t("document-metadata")}
                        </MDTypography>
                      </MDBox>
                      <DataTable
                        table={structureDataForTable(
                          documentDetails.metadata.sort(
                            (a, b) => moment(b.createdAt) - moment(a.createdAt)
                          )
                        )}
                        entriesPerPage={false}
                        canSearch
                      />
                    </Card>
                  </MDBox>
                </MDBox>
              </MDBox>
            ) : showReminderDetails ? (
              <MDBox style={{ display: "inline-flex" }}>
                <MDBox mb={2}>
                  <MDButton
                    variant="outlined"
                    color="primary"
                    onClick={handleHideClickReminderDetails}
                  >
                    {t("back-to-details")}
                  </MDButton>
                  <MDBox my={3}>
                    <Card>
                      <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {t("document-metadata")}
                        </MDTypography>
                      </MDBox>
                      <DataTable
                        table={structureRemindersForTable(
                          documentDetails.reminders.sort(
                            (a, b) => moment(b.createdAt) - moment(a.createdAt)
                          )
                        )}
                        entriesPerPage={false}
                        canSearch
                      />
                    </Card>
                  </MDBox>
                </MDBox>
              </MDBox>
            ) : (
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>
                  <Card>
                    <MDBox pt={2} px={2}>
                      <Header
                        document={documentDetails}
                        handleClick={handleClick}
                        handleOpenExtend={handleOpenExtend}
                        handleVerifyHash={handleVerifyHash}
                        loading={loadingDetails}
                        ownerActions={showOwnerActions}
                        lookupUsers={lookupUsers}
                        pdfPages={pdfPages}
                        handleClickDetails={handleClickDetails}
                        accessRead={accessRead}
                        handleClickReminderDetails={handleClickReminderDetails}
                        handleDownloadDocument={handleDownloadDocument}
                        // handleDownload={handleDownloadReport}
                        handleSetLoading={handleSetLoading}
                        disableSign={disableSign}
                      />
                    </MDBox>
                    <Divider />
                    <MDBox pt={1} pb={3} px={2}>
                      <MDBox mb={3}>
                        <OrderInfo
                          document={documentDetails}
                          loading={loadingDetails}
                          lookupUsers={lookupUsers}
                          ownerActions={showOwnerActions}
                          disableSign={disableSign}
                          accessRead={accessRead}
                        />
                      </MDBox>
                      <Divider />
                      <MDBox mt={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6} xl={4}>
                            <OrderSummary document={documentDetails} loading={loadingDetails} />
                          </Grid>
                          {/* <Grid item xs={12} lg={6} xl={4}>
                            <Signatures
                              signatures={
                                documentDetails.signatures ? documentDetails.signatures : []
                              }
                              title={t("signatures")}
                              shadow={false}
                              loading={loadingDetails}
                              lookupUsers={lookupUsers}
                            />
                          </Grid>
                          <Grid item xs={12} xl={4}>
                            <Recipients
                              title={t("recipients")}
                              profiles={documentDetails?.accessRights}
                              shadow={false}
                              handleAction={handleDeleteAccess}
                              loading={loadingDetails}
                              fileDetails={documentDetails}
                              ownerActions={showOwnerActions}
                              address={SDCAddress}
                              accessRead={accessRead}
                            />
                          </Grid> */}
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            )}
          </MDBox>

          <Dialog open={openVerifyHash} onClose={handleCloseVerify} fullWidth maxWidth="md">
            <DialogTitle>{t("validate-document-hash")}</DialogTitle>

            <DialogContent>
              <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                <Grid item xs={12}>
                  <MDTypography variant="caption">
                    {`${t(
                      "this-function-enables-you-to-verify-the-hash-of-a-local-PDF-document-against-the-PDF-document-stored-in-the-SignOnChain-solution-that-is-timestamped-on-the-blockchain"
                    )}`}
                    <br />
                    1.{" "}
                    {`${t(
                      "upload-or-drop-the-local-version-of-the-PDF-document-you-want-to-validate"
                    )}`}
                    <br />
                    2. {`${t("click-the-verify-button")}`}
                  </MDTypography>
                  <div style={{ textAlign: "center" }}>
                    <div className="picture1" style={{ marginBottom: "0px" }}>
                      <label
                        htmlFor="files"
                        style={{ display: "block", color: "rgb(123, 128, 154)" }}
                      >
                        <MDTypography variant="h6" color="dark">
                          {uploadedFile?.name ? uploadedFile?.name : t("upload-or-drop-file")}
                        </MDTypography>
                        <MDTypography variant="caption" color="dark">
                          {uploadedFile?.name ? "" : t("only-pdf-files-are-supported")}
                        </MDTypography>
                      </label>
                      <input
                        style={{
                          cursor: "pointer",
                          display: "block",
                          height: "100%",
                          left: "0",
                          opacity: "0",
                          position: "absolute",
                          top: "0",
                          width: "100%",
                        }}
                        type="file"
                        id="files"
                        accept="application/pdf"
                        ref={fileInput}
                        onChange={async (e) => {
                          const selectedFile = e.target.files[0];

                          if (selectedFile) {
                            setUploadedFile(selectedFile);
                          }
                        }}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              {verifyHashResponse.length > 0 && (
                <>
                  <MDTypography variant="h6">
                    {`${t("stored-hash")}: ${documentDetails.hash}`}
                  </MDTypography>
                  <MDTypography variant="h6">
                    {`${t("uploaded-hash")}: ${verifiedHash}`}
                  </MDTypography>
                  <MDTypography
                    variant="h6"
                    color={verifyHashResponse === t("validated") ? "success" : "warning"}
                  >
                    {`${t("result")}: ${verifyHashResponse}`}
                  </MDTypography>
                </>
              )}
              {loading && <LinearProgress sx={{ overflow: "hidden" }} />}
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseVerify}>{t("cancel")}</MDButton>
              <MDButton
                variant="gradient"
                color="primary"
                size="small"
                onClick={handleVerifyDocHash}
              >
                {t("verify")}
              </MDButton>
            </DialogActions>
          </Dialog>
          <Footer />
          <MDSnackbar
            icon="notifications"
            title={title}
            color={variant}
            content={message}
            open={show}
            close={handleClose}
            dateTime=""
          />
        </>
      )}
    </PublishLayout>
  );
}

export default PublishedDocument;
